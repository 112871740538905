import React, { useState } from 'react';
import Modal from 'react-modal';
import CookieConsent from 'react-cookie-consent';
import BounceLoader from 'react-spinners/BounceLoader';
import './App.css';
import Home from './components/Home.jsx';
import Set from './components/Set.jsx';
import Deck from './components/Cards.jsx';
import { useEffect } from 'react';

Modal.setAppElement('#root');

const isCodeValid = str => {
  if (!str) return false;
  if (str.length !== 4) return false;
  if (typeof str !== 'string') return false;
  str = str.toUpperCase();
  return str
    .split('')
    .map(char => char.charCodeAt())
    .reduce((acc, char) => acc && char >= 65 && char <= 90, true);
};

const codeToUpper = str => str.toUpperCase();

const getCodeFromQueryParam = () => {
  const pairs = window.location.search.slice(1).slice(0).split('&');
  const obj = {};
  pairs.forEach(str => {
    const [key, val] = str.split('=');
    obj[key] = val;
  });

  if (obj['code']) return obj['code'];
  return null;
};

function App() {
  const [content, setContent] = useState(null);
  // const [aboutVisible, setAbout] = useState(false);
  const [loader, setLoader] = useState(false);

  // const toggleAbout = () => setAbout(!aboutVisible);

  useEffect(() => {
    if (localStorage.getItem('likes')) {
      const localStoreLikes = JSON.parse(localStorage.getItem('likes'));

      // remove this eventually
      if (typeof localStoreLikes[0] !== 'string') {
        resetAll();
        return;
      }

      const movies = JSON.parse(localStorage.getItem('movies'));
      const code = localStorage.getItem('code');

      setContent(
        <Deck
          movies={movies}
          code={code}
          localStoreLikes={localStoreLikes}
          setContent={setContent}
          setLoader={setLoader}
        />,
      );
      return;
    }

    let code = getCodeFromQueryParam();
    if (isCodeValid(code)) {
      code = codeToUpper(code);
      getRecordWithCode(code);
      return;
    }

    code = localStorage.getItem('code');
    if (isCodeValid(code)) {
      code = codeToUpper(code);
      getRecordWithCode(code);
    } else {
      setContent(<Home setContent={setContent} setLoader={setLoader} />);
    }
  }, []);

  const getRecordWithCode = code => {
    setLoader(true);
    fetch(`/api/record?key=${code}`)
      .then(res => res.json())
      .then(({ numPlayers, set, message }) => {
        if (message) throw new Error(message);
        localStorage.setItem('movies', JSON.stringify(set));
        localStorage.setItem('code', code);
        setContent(
          <Set
            code={code}
            numPlayers={numPlayers}
            set={set}
            setContent={setContent}
            setLoader={setLoader}
          />,
        );
        setLoader(false);
      })
      .catch(() => {
        setContent(<Home setContent={setContent} setLoader={setLoader} />);
        setLoader(false);
      });
  };

  const resetAll = () => {
    localStorage.clear();
    setContent(<Home setContent={setContent} setLoader={setLoader} />);
  };

  const code = localStorage.getItem('code');

  return (
    <div className='app'>
      <header>
        {code ? (
          <button className='app__button' onClick={resetAll} tabIndex={0}>
            Reset Code
          </button>
        ) : (
          <div className='app__code'>{code}</div>
        )}
        <h1>Netflix Swipe</h1>
        <div className='app__code'>{code}</div>
      </header>
      <main>{content}</main>
      <div className='app__loader'>
        <BounceLoader loading={loader} size={50} color='#ffcb37' />
      </div>
      {/* <footer>
        <button onClick={toggleAbout} className='footer__button'>
          About
        </button>
      </footer> */}
      {/* <Modal isOpen={aboutVisible} onRequestClose={toggleAbout}>
        <p className='modal'>
          This app was made me,{' '}
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://amoghk.com'
          >
            Amogh Kambale
          </a>
          . I made it for fun. If you came here via an ad, I buy those mostly to
          learn about web marketing, not becuase I'm trying to sell you
          anything. Maybe I'll put ads on here someday if the server costs get
          too high, but the app will always remain free.
        </p>
        <button className='home__button modal__close' onClick={toggleAbout}>
          Close
        </button>
      </Modal> */}
      <CookieConsent
        location='bottom'
        buttonText='OK'
        cookieName='agreeToCookies'
        style={{ background: '#ffcb37', color: 'black' }}
        buttonStyle={{
          color: 'black',
          fontSize: '13px',
          backgroundColor: 'white',
        }}
        expires={150}
      >
        This website uses cookies.{' '}
      </CookieConsent>
      <button className='conversion'>Submit Deck</button>
    </div>
  );
}

export default App;
