import React, { useRef, useState } from 'react';
import Modal from 'react-modal';
import Deck from './Cards.jsx';
import './Set.css';

Modal.setAppElement('#root');

function Set({ code, numPlayers, set, setContent, setLoader }) {
  const [showResults, setShowResults] = useState(false);
  const inputRef = useRef(null);
  const [isOpen, showModal] = useState(false);
  const [err, setErr] = useState('');

  const copy = e => {
    e.preventDefault();
    inputRef.current.select();
    document.execCommand('copy');
  };

  const getRecordsWithCode = code => {
    setLoader(true);
    fetch(`/api/record?key=${code}`)
      .then(res => res.json())
      .then(({ numPlayers, set }) => {
        localStorage.setItem('movies', JSON.stringify(set));
        localStorage.setItem('code', code);
        setContent(
          <Set
            code={code}
            numPlayers={numPlayers}
            set={set}
            setContent={setContent}
            setLoader={setLoader}
          />,
        );
        setLoader(false);
      })
      .catch(err => {
        showModal(true);
        setErr(err.message);
        setLoader(false);
      });
  };

  const setDeck = () => {
    setContent(
      <Deck
        setContent={setContent}
        movies={set}
        code={code}
        setLoader={setLoader}
      />,
    );
  };

  return (
    <div className='top-level-container'>
      <div className='set__container'>
        <div className='set__container__text'>
          <p>
            Players Done Swiping: <span>{numPlayers}</span>
          </p>
          <p>Share the code below with a friend while you start swiping</p>
          <p className='set__code'>{code}</p>
        </div>
        <div className='set__link__container'>
          <input
            className='set__input'
            dir={'rtl'}
            type={'text'}
            value={`${window.location.origin}?code=${code}`}
            ref={inputRef}
            onClick={copy}
            readOnly={true}
          />
          <button className='set__link__button' onClick={copy}>
            Copy
          </button>
        </div>
        <button className='set__button' onClick={setDeck}>
          Start Swiping On Deck
        </button>
        <button
          className='set__button'
          onClick={() => getRecordsWithCode(code)}
        >
          Refresh Results
        </button>
        <button
          className='set__button'
          onClick={() => {
            setShowResults(true);
            setTimeout(() => window.scroll(0, window.innerHeight), 100);
          }}
        >
          View Swipe Results
        </button>
      </div>
      {showResults ? <Results set={set} numPlayers={numPlayers} /> : null}
      <Modal isOpen={isOpen}>
        <p className='modal'>{err}</p>
        <button
          className='home__button modal__close'
          onClick={() => showModal(false)}
        >
          Close
        </button>
      </Modal>
    </div>
  );
}

function Results({ set, numPlayers }) {
  const setCopy = [...set];
  setCopy.sort((a, b) => b.numLikes - a.numLikes);
  const tiles = setCopy.map(({ image, title, netflixid, numLikes }) => (
    <ResultTile
      image={image}
      title={title}
      key={netflixid}
      numLikes={numLikes}
      numPlayers={numPlayers}
    />
  ));

  return (
    <div className='results'>
      <p>Decks will expire in 72 hours from last swipe</p>
      <ul className='results__list'>{tiles}</ul>
    </div>
  );
}

function ResultTile({ image, title, numPlayers, numLikes }) {
  let percentage = 0;
  if (numPlayers > 0) {
    percentage = Math.floor((numLikes / numPlayers) * 100);
  }
  return (
    <li className='results__list__item'>
      <img className='results__img' src={image} alt={title}></img>
      <p>{`${percentage}% Like`}</p>
    </li>
  );
}

export default Set;
