import React, { useState } from 'react';
import Modal from 'react-modal';
import Swipe from './Swipe.jsx';
import Set from './Set.jsx';
import './Cards.css';

Modal.setAppElement('#root');

const dateConverter = str => {
  const [, month, day] = str.split('-');
  const monthMap = {
    '01': 'Jan',
    '02': 'Feb',
    '03': 'Mar',
    '04': 'Apr',
    '05': 'May',
    '06': 'June',
    '07': 'July',
    '08': 'Aug',
    '09': 'Sept',
    10: 'Oct',
    11: 'Nov',
    12: 'Dec',
  };
  const finalMonth = monthMap[month];

  let end;
  if (day === '11' || day === '12' || day === '13') {
    end = 'th';
  } else if (day[1] === '1') {
    end = 'st';
  } else if (day[1] === '2') {
    end = 'nd';
  } else if (day[1] === '3') {
    end = 'rd';
  } else {
    end = 'th';
  }
  let finalDay = day;
  if (finalDay[0] === '0') {
    finalDay = finalDay.slice(1);
  }

  return `${finalMonth} ${finalDay}${end}`;
};

function Deck({ movies, setContent, code, localStorageLikes, setLoader }) {
  const [likes, setLikes] = useState(localStorageLikes || []);
  const [index, setIndex] = useState(1);
  const [moviesToShow, setMoviesToShow] = useState(movies);
  const [showShrink, setShowShrink] = useState(true);
  const [isOpen, showModal] = useState(false);
  const [err, setErr] = useState('');

  const like = data => {
    const nLikes = likes.concat(data);
    localStorage.setItem('likes', JSON.stringify(nLikes));
    setLikes(nLikes);
    handleNext();
  };

  const handleNext = () => {
    setShowShrink(false);
    setTimeout(() => {
      const nMovies = moviesToShow.slice(1);
      setMoviesToShow(nMovies);
      setShowShrink(true);
      setIndex(index + 1);

      localStorage.setItem('movies', JSON.stringify(nMovies));
      localStorage.setItem('code', code);

      if (nMovies.length === 0) {
        submit();
      }
    }, 500);
  };

  const submit = () => {
    setLoader(true);
    const bodyStr = JSON.stringify({
      key: code,
      likes,
    });
    document.querySelector('.conversion').click();
    setLoader(true);

    fetch('/api/record', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: bodyStr,
    })
      .then(res => res.json())
      .then(({ key, numPlayers, set, message }) => {
        if (message) throw new Error(message);
        setContent(
          <Set
            code={key}
            numPlayers={numPlayers}
            set={set}
            setContent={setContent}
            setLoader={setLoader}
          />,
        );
        localStorage.clear();
        localStorage.setItem('code', key);
        localStorage.setItem('movies', JSON.stringify(set));
        setLoader(false);
      })
      .catch(err => {
        showModal(true);
        setErr(err.message);
        setLoader(false);
      });
  };

  return (
    <div className='top-level-container'>
      <div>
        {moviesToShow[0] ? (
          <Swipe
            key={moviesToShow[0].netflixid}
            style={{ zIndex: -10 }}
            className='swipe__child'
            customOnSwipeRight={() => like(moviesToShow[0].netflixid)}
            customOnSwipeLeft={handleNext}
          >
            <Card
              data={moviesToShow[0]}
              index={index}
              moviesLength={movies.length}
            />
          </Swipe>
        ) : (
          <button
            className='home__button err__button'
            onClick={e => {
              e.preventDefault();
              submit();
            }}
          >
            Try Submitting Again
          </button>
        )}
        {moviesToShow[1] ? (
          <Swipe
            key={moviesToShow[1].netflixid}
            style={{ zIndex: -20 }}
            className={`swipe__child card__normal${
              showShrink ? ' card__shrink' : ''
            }`}
          >
            <Card
              data={moviesToShow[1]}
              index={index}
              moviesLength={movies.length}
            />
          </Swipe>
        ) : null}
      </div>
      <Modal isOpen={isOpen}>
        <p className='modal'>{err}</p>
        <button
          className='home__button modal__close'
          onClick={() => showModal(false)}
        >
          Close
        </button>
      </Modal>
    </div>
  );
}

const Image = ({ src, setImageWorks }) => (
  <img
    src={src}
    onError={() => setImageWorks(false)}
    alt={'title here'}
    hidden={true}
    onLoad={() => setImageWorks(true)}
  />
);

function Card({ data, index, moviesLength, swipeRight, swipeLeft, superLike }) {
  const [smallImageWorks, setSmallImageWorks] = useState(true);
  const [largeImageWorks, setLargeImageWorks] = useState(true);
  const [scrollPercent, setScrollPercent] = useState(0);

  const { title, image, largeimage, synopsis, type, released, runtime } = data;

  let expires = null;
  let snippedSynopsis = null;
  if (synopsis.includes('<br><b>')) {
    const i = synopsis.indexOf('<br><b>');
    snippedSynopsis = synopsis.slice(0, i);
    expires = synopsis.slice(i + 18, -4);
    expires = `Expires on ${dateConverter(expires)}`;
  }

  const handleScroll = e => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    const height = scrollHeight - clientHeight;
    const percent = Math.round((scrollTop / height) * 100);
    setScrollPercent(percent);
  };

  let firstImage,
    secondImage = null;
  if (largeImageWorks) {
    firstImage = <img id='card__img' alt={title} src={largeimage} />;
    if (smallImageWorks) {
      secondImage = <img id='card__img' alt={title} src={image} />;
    }
  } else if (smallImageWorks) {
    firstImage = <img id='card__img' alt={title} src={image} />;
  }

  return (
    <div className='card-container--parent'>
      <Image src={image} setImageWorks={setSmallImageWorks} />
      <Image src={largeimage} setImageWorks={setLargeImageWorks} />
      <div className='card__scroll'>
        <div
          className='card__scroll__bar'
          style={{ top: `${scrollPercent * 0.8}%` }}
        />
      </div>
      <div className='card-container--child' onScroll={handleScroll}>
        {firstImage}
        <div className='info-container'>
          <p
            dangerouslySetInnerHTML={{ __html: snippedSynopsis || synopsis }}
          />
          <ul className='info-container__list'>
            <li>{type[0].toUpperCase() + type.slice(1)}</li>
            <li>{released}</li>
            {runtime ? <li>{runtime}</li> : null}
            {expires ? <li>{expires}</li> : null}
          </ul>
        </div>
        {secondImage}
        <div className='info-container__lower'>
          <h2 dangerouslySetInnerHTML={{ __html: title }}></h2>
          <div className='hex'>
            <div
              className='info-container__lower__progress'
              onClick={superLike}
              onKeyDown={e => {
                if (e.key === 'Enter') superLike();
              }}
              tabIndex={0}
            >
              {`${index}/${moviesLength}`}
            </div>
          </div>
          <div className='like-dislike__container'>
            <button
              onClick={swipeLeft}
              className='like-dislike dislike'
            ></button>
            <button onClick={swipeRight} className='like-dislike like'></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Deck;
