import React, { useState } from 'react';
import Modal from 'react-modal';
import Set from './Set.jsx';
import './Home.css';

Modal.setAppElement('#root');

const isCodeValid = str => {
  if (!str) return false;
  if (str.length !== 4) return false;
  if (typeof str !== 'string') return false;
  str = str.toUpperCase();
  return str
    .split('')
    .map(char => char.charCodeAt())
    .reduce((acc, char) => acc && char >= 65 && char <= 90, true);
};

function Home({ setContent, setLoader }) {
  const [code, setCode] = useState('');
  const [isOpen, showModal] = useState(false);
  const [err, setErr] = useState('');

  const getRecordWithCode = e => {
    e.preventDefault();
    if (!isCodeValid(code)) {
      setErr(
        'Codes must be four letters long. Please check your code and enter again',
      );
      showModal(true);
      return;
    }
    setLoader(true);
    fetch(`/api/record?key=${code}`)
      .then(res => res.json())
      .then(({ numPlayers, set, message }) => {
        if (message) throw new Error(message);
        localStorage.setItem('movies', JSON.stringify(set));
        localStorage.setItem('code', code);
        setContent(
          <Set
            code={code}
            numPlayers={numPlayers}
            set={set}
            setContent={setContent}
            setLoader={setLoader}
          />,
        );
        setLoader(false);
      })
      .catch(err => {
        setErr(err.message);
        showModal(true);
        setLoader(false);
      });
  };

  const getNewMovies = e => {
    setLoader(true);
    e.preventDefault();
    fetch('/api/record')
      .then(res => res.json())
      .then(({ numPlayers, set, key, message }) => {
        if (message) throw new Error(message);
        localStorage.setItem('movies', JSON.stringify(set));
        localStorage.setItem('code', key);
        setContent(
          <Set
            code={key}
            numPlayers={numPlayers}
            set={set}
            setContent={setContent}
            setLoader={setLoader}
          />,
        );
        setLoader(false);
      })
      .catch(err => {
        setErr(err);
        showModal(true);
        setLoader(false);
      });
  };

  return (
    <div className='top-level-container'>
      <form id='start-container'>
        <div className='home__code-group'>
          <p>Already have a code?</p>
          <input
            className='home__input'
            onChange={e => setCode(e.target.value.toUpperCase())}
            type={'text'}
            maxLength={'4'}
            pattern={'[A-Za-z]{4}'}
            onInvalid={() => {
              setErr('codes consist of four letters');
              showModal(true);
            }}
          />
          <button className='home__button' onClick={getRecordWithCode}>
            Submit
          </button>
        </div>
        <button className='home__button' onClick={getNewMovies}>
          Start New Deck
        </button>
      </form>
      <Modal isOpen={isOpen}>
        <p className='modal'>{err}</p>
        <button
          className='home__button modal__close'
          onClick={() => showModal(false)}
        >
          Close
        </button>
      </Modal>
    </div>
  );
}

export default Home;
