import React from 'react';
import './Swipe.css';

const img = new Image();
// img.src = `${process.env.PUBLIC_URL}/1x1.png`;
img.src = `./1x1.png`;


class Swipe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      initX: 0,
      posX: 0,
      posXTouch: 0,
      posRot: 0,
      transform: '',
      animationName: '',
    };
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleTouchStart = this.handleTouchStart.bind(this);
    this.handleDragEnd = this.handleDragEnd.bind(this);
    this.handleTouchEnd = this.handleTouchEnd.bind(this);
    this.handleTouchCancel = this.handleTouchCancel.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTouch = this.handleTouch.bind(this);
    this.animateBack = this.animateBack.bind(this);
    this.swipeRight = this.swipeRight.bind(this);
    this.swipeLeft = this.swipeLeft.bind(this);
    this.superLike = this.superLike.bind(this);
  }

  handleDragStart(event) {
    const { clientX } = event;
    this.setState({ initX: clientX });
    event.dataTransfer.setDragImage(img, 0, 0);
  }

  handleTouchStart(event) {
    const { clientX } = event.touches[0];
    this.setState({ initX: clientX });
  }

  handleDragEnd(event) {
    const { swipeRange } = this.props;
    const { swipeRight, swipeLeft } = this;
    const range = swipeRange ? swipeRange : 100;

    const { clientX } = event;
    const diff = clientX - this.state.initX;
    if (diff > range) {
      swipeRight();
    } else if (diff > 0 && diff < 20) {
      this.animateBack('');
    } else if (diff < -range) {
      swipeLeft();
    } else if (diff < 0 && diff > -20) {
      this.animateBack('');
    } else if (diff > 0) {
      this.animateBack('right');
    } else {
      this.animateBack('left');
    }
  }

  handleTouchEnd() {
    const { swipeRange } = this.props;
    const { posXTouch, initX } = this.state;
    const { swipeRight, swipeLeft } = this;
    const range = swipeRange ? swipeRange : 100;

    const diff = posXTouch - initX;

    if (posXTouch === 0) return;

    if (diff > range) {
      swipeRight();
    } else if (diff > 0 && diff < 20) {
      this.animateBack('');
    } else if (diff < -range) {
      swipeLeft();
    } else if (diff < 0 && diff > -20) {
      this.animateBack('');
    } else if (diff > 0) {
      this.animateBack('right');
    } else {
      this.animateBack('left');
    }
  }

  handleTouchCancel() {
    this.animateBack('');
  }

  handleDrag(event) {
    const { rotateRange } = this.props;
    const range = rotateRange ? rotateRange : 10;

    const { clientX } = event;
    const x = clientX - this.state.initX;
    const rot = Math.floor(x / range);
    this.setState({
      posX: x,
      posRot: rot,
      transform: `translateX(${x}px) rotate(${rot}deg)`,
    });
  }

  handleTouch(event) {
    const { rotateRange } = this.props;
    const range = rotateRange ? rotateRange : 10;
    const { clientX } = event.touches[0];
    const x = clientX - this.state.initX;
    const rot = Math.floor(x / range);
    this.setState({
      posXTouch: clientX,
      posX: x,
      posRot: rot,
      transform: `translateX(${x}px) rotate(${rot}deg)`,
    });
  }

  animateBack(direction) {
    this.setState({ animationName: `normal${direction}` });
    setTimeout(() => {
      this.setState({
        transform: 'none',
        animationName: '',
        posXTouch: 0,
      });
    }, 500);
  }

  swipeLeft() {
    const { customOnSwipeLeft } = this.props;
    this.setState({ animationName: 'flyleft' });
    if (customOnSwipeLeft) customOnSwipeLeft();
  }

  swipeRight() {
    const { customOnSwipeRight } = this.props;
    this.setState({ animationName: 'flyright' });
    if (customOnSwipeRight) customOnSwipeRight();
  }

  superLike() {
    const { customOnSwipeRight } = this.props;
    this.setState({ animationName: 'flyup' });
    if (customOnSwipeRight) customOnSwipeRight();
  }

  render() {
    const { animationName, transform } = this.state;
    const { children, style } = this.props;
    const {
      swipeRight,
      swipeLeft,
      handleDragStart,
      handleDragEnd,
      handleDrag,
      handleTouchStart,
      handleTouchEnd,
      handleTouch,
      handleTouchCancel,
      superLike,
    } = this;
    const swipeStyle = {
      ...style,
      animationName,
      transform,
      animationFillMode: 'forwards',
      animationDuration: '1.5s',
      transitionTimingFunction: 'ease-in-out',
    };
    return (
      <div
        {...this.props}
        style={swipeStyle}
        draggable={true}
        onDragStart={handleDragStart}
        onTouchStart={handleTouchStart}
        onDragEnd={handleDragEnd}
        onTouchEnd={handleTouchEnd}
        onTouchCancel={handleTouchCancel}
        onDrag={handleDrag}
        onTouchMove={handleTouch}
        onDragOver={e => e.preventDefault()}
      >
        {React.cloneElement(children, { swipeRight, swipeLeft, superLike })}
      </div>
    );
  }
}

export default Swipe;
